import React from 'react';
import PageContainer from './components/PageContainer';


function App() {
    
    return (
        <PageContainer />
    );
}

export default App; 